import { Button, Spin } from "antd";
import { StarFilled, ArrowRightOutlined } from "@ant-design/icons";
import CheckIcon from "static/icons/checked.png";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function SitRelax(props) {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);
  return (
    <div
      className="Greeting"
      style={{
        padding: "20px 28px",
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="FinishScreen">
        <img src={CheckIcon} alt="finishIllustration" />
        <Typography
          style={{ marginTop: 12, color: colorPicker("neutral.900") }}
          weight={600}
          variant="h5"
        >
          That's All
        </Typography>
        <Typography
          style={{
            marginTop: 6,
            textAlign: "center",
            color: colorPicker("neutral.800"),
          }}
          variant="caption"
        >
          We have received your credentials and started preparing platform for
          you. <br />{" "}
          <b>
            Meanwhile you can add more business and members , redirecting
            dashboard
          </b>
        </Typography>
      </div>
    </div>
  );
}
