import { useCallback, memo } from "react";
import React from "react";

const ToolParamInvoker = (params: any) => {
  console.log("param is:", params);
  const { value, handleClick, label, data } = params;

  const onLinkClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleClick(params, { pdfData: data });
    },
    [handleClick, params]
  );

  return (
    <div onClick={onLinkClick} title={value}>
      <div role="button" className={`link-primary text-truncate `}>
        {label || "View Invoice"}
      </div>
    </div>
  );
};

export default memo(ToolParamInvoker);
