import Typography from "app/shared/Typography";
import "./OnboardingInviteUser.scss";
import { colorPicker } from "app/utils/color.helper";
import OTP from "antd/es/input/OTP";
import { Button, Input } from "antd";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import certificationImage from "static/images/certificationImage.png";
import onboardingIllustration from "static/images/onboardingIllustration.png";
import onboardingIllustration2 from "static/images/onboardingIllustration2.png";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import OnboardingHeader from "../OnboardingHeader";
import TextField from "app/shared/TextField";
import { cursorTo } from "readline";
import OnboardingInviteUserAdd from "./components/OnboardingInviteUser";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { onboardInfo, userInfo } from "app/config/States/users";
import GDPRLogo from "static/images/compliance/GDPR.png";
import ISO27001 from "static/images/compliance/ISO27001.png";
import ISO27018 from "static/images/compliance/ISO-27018-2019.png";
import ISO27017 from "static/images/compliance/ISO-27017-2015.png";

export default function OnboardingInviteUser(props: any) {
  const [activeScreen, setActiveScreen] = useState(0);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [userOnboardInfo, setOnboardInfo] = useRecoilState<any>(onboardInfo);

  const location = useLocation();
  const { state } = location || {};
  return (
    <div className="OnboardingInviteUser">
      <img
        src={onboardingIllustration}
        alt="Illustartion"
        className="backgroundIllustration"
      />
      <div className="HeaderContainer">
        <OnboardingHeader
          title={userOnboardInfo?.currentWorkspaceName || "Test Workspace"}
          noShadow
        />
      </div>
      <div className="WrapperContainer">
        {/* <OnboardingInviteUserAdd /> */}

        <OnboardingInviteUserAdd
          inviteUser={() => setActiveScreen(0)}
          onCancel={() => setActiveScreen(0)}
        />

        <div className="RightSection">
          <h1 className="GSTIllustration">GST</h1>
          <div>
            <Typography variant="h6" style={{ position: "absolute" }}>
              Claiming input tax credit has never been easier.
            </Typography>
            <img
              src={onboardingIllustration2}
              alt="illustrationImage"
              className="rightIllustration"
            />
          </div>

          <div>
            <Typography>Goods And Service Tax</Typography>
            <Typography
              variant="xs"
              style={{ color: colorPicker("neutral.800") }}
            >
              Claiming GST for travel is important because it helps your
              business save money by getting back the tax you paid on travel
              expenses like flights and hotels. This means you can reduce your
              overall costs and have more money available for other parts of
              your business.
            </Typography>
          </div>
        </div>
      </div>
      <div className="BottomReference">
        <div className="ImageContainer">
          <img
            style={{ widows: 172, height: 24 }}
            src={poweredFinkraft}
            alt="poweredLogo"
          />
        </div>

        <div className="ComplianceContainer">
          <img src={GDPRLogo} alt="GDRPLogo" style={{ width: 38 }} />
          <img src={ISO27001} alt="ISO27001 Logo" style={{ width: 38 }} />
          <img src={ISO27017} alt="ISO27017 Logo" style={{ width: 38 }} />
          <img src={ISO27018} alt="ISO27018 Logo" style={{ width: 38 }} />
        </div>
      </div>
    </div>
  );
}
