import React from "react";
import PropTypes from "prop-types";
import { Image } from "antd";
import Typography from "../Typography";
import DefaultIllustration from "static/images/illustration/empty_state.png";
import { colorPicker } from "app/utils/color.helper";
const EmptyState = ({ imageSrc, description, title, allowTicket }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Image
        src={imageSrc || DefaultIllustration}
        width={160}
        preview={false}
        style={{ marginBottom: 24 }}
      />
      <Typography weight={600} style={{ color: colorPicker("neutral.600") }}>
        {title || "Something went wrong"}
      </Typography>
      <Typography
        variant="xs"
        style={{ color: colorPicker("neutral.600"), marginTop: 6 }}
      >
        {description ||
          "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."}
        {allowTicket ? (
          <span style={{ marginLeft: 4, color: colorPicker("primary.700") }}>
            Raise a Ticket
          </span>
        ) : null}
      </Typography>
    </div>
  );
};

export default EmptyState;
