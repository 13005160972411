import { Button, message } from "antd";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "../OnboardingInviteUser.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOOINT_ONBOARDING_INVITE_MEMBER } from "app/scenes/OnboardingV2/onboardingv2.constants";
import OnboardingHelpCenter from "../../OnboardingHelpCenter";
export default function OnboardingInviteUserAdd(props: any) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);

  const handleInviteMember = async () => {
    setLoading(true);
    const paylaod = {
      name: name,
      email: email,
      phone: phone,
      token: localStorage.getItem("token"),
      workspaceid: localStorage.getItem("currentWorkspace"),
    };

    const response = await apiPost(
      API_ENDPOOINT_ONBOARDING_INVITE_MEMBER,
      paylaod
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setEmail("");
      setName("");
      setPhone("");
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };
  return (
    <div className="LeftSection">
      <Typography variant="h6" style={{ color: colorPicker("neutral.800") }}>
        Add a member from{" "}
        <b style={{ color: colorPicker("primary.700") }}>TAX team </b>who can
        help with integration
      </Typography>
      <div className="FormGroup">
        <div className="FieldContainer">
          <TextField
            placeholder="Name"
            label="Name"
            labelColor={colorPicker("neutral.800")}
            height={42}
            onChange={(e: any) => setName(e.target.value)}
            defaultValue={name}
          />
        </div>

        <div className="FieldContainer">
          <TextField
            placeholder="Email"
            label="Email"
            labelColor={colorPicker("neutral.800")}
            height={42}
            onChange={(e: any) => setEmail(e.target.value)}
            defaultValue={email}
          />
        </div>

        <div className="FieldContainer">
          <TextField
            placeholder="Phone"
            label="Phone"
            labelColor={colorPicker("neutral.800")}
            height={42}
            onChange={(e: any) => setPhone(e.target.value)}
            defaultValue={phone}
          />
        </div>

        <div className="ActionContainer">
          <Button
            style={{ width: "49%" }}
            size="large"
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
          <Button
            style={{ width: "49%" }}
            type="primary"
            size="large"
            onClick={handleInviteMember}
            loading={isLoading}
          >
            Send Invite
          </Button>
        </div>

        <div style={{ marginTop: 32 }}>
          <OnboardingHelpCenter
            title="Help Center"
            color="neutral.700"
            noDescription
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
