import { Button, message } from "antd";
import { apiPost } from "app/services/apiServices";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useState } from "react";
import Lottie from "react-lottie";
import DoneLottie from "static/lottie/doneLottie.json";
import { useNavigate } from "react-router-dom";
import { MarginTwoTone } from "@mui/icons-material";
import { PlusOutlined } from "@ant-design/icons";
import {
  API_ENDPOOINT_ONBOARDING_RAISE_DATA_REQUEST,
  API_ENDPOOINT_ONBOARDING_UPDATE_WORKSPACE_META,
} from "../../onboardingv2.constants";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import moment from "moment";

export default function RequestDataForm(props: any) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [requested, setRequested] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const handleSendRequest = async () => {
    setLoading(true);

    const response = await apiPost(API_ENDPOOINT_ONBOARDING_RAISE_DATA_REQUEST);

    if (response.status) {
      handleLogRequestTime();
      messageApi.open({
        type: "success",
        content: "Request sent!",
      });
      setRequested(true);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not send request",
      });
    }

    setLoading(false);
  };

  const handleLogRequestTime = async () => {
    const response = await apiPost(
      API_ENDPOOINT_ONBOARDING_UPDATE_WORKSPACE_META,
      { requestedat: true }
    );

    if (response.status) {
      setRequested(true);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not send request",
      });
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: DoneLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="AddGstinWidget"
      style={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/61/Makemytrip_logo.svg"
        alt="headerLogo"
        style={{
          width: 88,
          marginBottom: requested || userDetails?.meta?.requestedat ? 44 : 0,
        }}
      />

      {!requested && !userDetails?.meta?.requestedat ? (
        <>
          <Typography variant="h6" style={{ marginTop: 24 }}>
            Please raise a request for data
          </Typography>
          <Typography
            variant="xs"
            style={{ marginTop: 6, color: colorPicker("neutral.700") }}
          >
            looks like there is no data available for this workspace please
            raise a request
          </Typography>

          <Button
            style={{ width: 200, marginTop: 28 }}
            type="primary"
            onClick={handleSendRequest}
            loading={isLoading}
          >
            Raise Request
          </Button>
        </>
      ) : (
        <>
          <Lottie options={lottieOptions} height={88} width={88} />

          <Typography
            variant="h6"
            style={{ marginTop: 12, color: colorPicker("neutral.800") }}
            weight={500}
          >
            {userDetails?.meta?.requestedat
              ? `Request for already submitted ${moment(
                  userDetails?.meta?.requestedat
                ).fromNow()}`
              : "Request sent"}
          </Typography>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700") }}
          >
            Request has been sent to MMT to look at your booking details and it
            will be shared soon.
          </Typography>

          <Typography
            variant="caption"
            style={{
              color: colorPicker("neutral.700"),
              marginTop: 24,
              textAlign: "center",
            }}
          >
            Please feel free to reach out to us for assistance
            <br />{" "}
            <span
              style={{
                color: colorPicker("primary.700"),
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Call +917895364466
            </span>
          </Typography>

          <Button
            type="primary"
            style={{ marginTop: 24 }}
            icon={<PlusOutlined />}
            onClick={() => navigate("/onboarding2/invite_user")}
          >
            Invite a Tax Colleague
          </Button>
        </>
      )}
      {contextHolder}
    </div>
  );
}
