import { FloatButton } from "antd";
import Typography from "app/shared/Typography";
import ReactPlayer from "react-player";
import { RightCircleOutlined } from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import JsonData from "./jsonData.json";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";

export default function OnboardHotelReconView(props: any) {
  const openSidebarRenderer = (params: any) => {
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const columnDefs = [
    {
      headerName: "Invoice",
      field: "Invoice_link",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: () => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
        };
      },
    },
    {
      headerName: "Vendor Name",
      field: "vendor_name_einvoice",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },

    {
      headerName: "MMT Booking Id",
      field: "MMT Booking Id",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor State",
      field: "Vendor State",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Vendor GSTIN",
      field: "vendor_gstin",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor PAN",
      field: "vendor_pan",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Customer GSTIN",
      field: "customer_gstin",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Customer PAN",
      field: "customer_pan",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Invoice Number",
      field: "invoice_number",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Invoice Date",
      field: "invoice_date",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Taxable Amount",
      field: "taxable_amount",
      filter: "agNumberColumnFilter",
      formatType: "AMOUNT",
    },
    {
      headerName: "CGST Amount",
      field: "cgst_amount",
      filter: "agNumberColumnFilter",
      formatType: "AMOUNT",
    },
    {
      headerName: "SGST Amount",
      field: "sgst_amount",
      filter: "agNumberColumnFilter",
      formatType: "AMOUNT",
    },
    {
      headerName: "Total GST",
      field: "Total GST",
      filter: "agNumberColumnFilter",
      formatType: "AMOUNT",
    },
    {
      headerName: "Total Amount",
      field: "total_amount",
      filter: "agNumberColumnFilter",
      formatType: "AMOUNT",
    },
    {
      headerName: "Invoice Type",
      field: "invoice_type",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Filing Period",
      field: "filing_period",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Eligibility Status",
      field: "eligiblity_status",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Unique Identifier",
      field: "eunique",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Buyer GSTIN",
      field: "BuyerDtls.Gstin",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Document Number",
      field: "DocDtls.No",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Document Date",
      field: "DocDtls.Dt",
      filter: "agDateColumnFilter",
    },
    { headerName: "IRN", field: "Irn", filter: "agTextColumnFilter" },
    { headerName: "IRN Date", field: "IrnDt", filter: "agDateColumnFilter" },
    {
      headerName: "CGST Amount",
      field: "CgstAmt",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "SGST Amount",
      field: "SgstAmt",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "IGST Amount",
      field: "IgstAmt",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "IRN Total GST",
      field: "irn Total GST",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Vendor Type",
      field: "Vendor_Type_Irn",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },

    {
      headerName: "Invoice Status",
      field: "Invoice Status",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "GST Difference",
      field: "gstdifference",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "GST Status",
      field: "GST Status",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor GST Status",
      field: "Vendor GST Status",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Invoice Number Status",
      field: "Invoice Number Status",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Match Status",
      field: "Match Status",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Claimable Eligibility Status",
      field: "Claimable Eligibility_Status",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
  ];
  return (
    <div className="Greeting" style={{ height: "100%" }}>
      <div className="TableContainer" style={{ height: "100%" }}>
        <AgTableClient
          // @ts-ignore
          rowData={JsonData}
          columnDefs={columnDefs}
          groupPanel
          enablePDFViewer
          pdfDataKey="Invoice_link"
          onColGroup={props.onColGroup && props.onColGroup}
          defaultPivot={[""]}
          // autoGroupColumnDef={["Vendor Name"]}
          // pinnedBottomRowData={summarizedData}
        />
      </div>
      {props.noFloat ? null : (
        <FloatButton
          onClick={props.goNext}
          style={{ marginRight: 24, width: 200 }}
          shape="square"
          type="primary"
          icon={
            <div style={{ display: "flex", width: "100%" }}>
              <Typography style={{ color: "white", marginRight: 6 }}>
                Next
              </Typography>
              <RightCircleOutlined />
            </div>
          }
        />
      )}
    </div>
  );
}
