import { FloatButton, Tabs } from "antd";
import UnReconciledHotel from "./UnReconciledHotel";
import UnReconciledFlight from "./UnReconciledFlight";
import "./MMT.scss";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
export default function OnboardUnReconciled(props: any) {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Flight Booking",
      children: <UnReconciledFlight {...props} goNext={props.goNext} />,
    },
    {
      key: "2",
      label: "Hotel Booking",
      children: <UnReconciledHotel {...props} goNext={props.goNext} />,
    },
  ];

  return (
    <div
      className="Greeting"
      style={{ paddingTop: "20px", height: "100%", boxSizing: "border-box" }}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

      <FloatButton
        onClick={props.goNext}
        style={{ marginRight: 24 }}
        shape="circle"
        type="primary"
        icon={<RightCircleOutlined />}
      />
    </div>
  );
}
