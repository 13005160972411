import apptourRoutes from "app/scenes/AppTour/apptour.routes";
import SampleReconViewAGTable from "app/scenes/AppTour/components/SampleReconView/sampleGSTTableView";
import authRoute from "app/scenes/Auth/auth.routes";
import PageNotFound from "app/scenes/Common/PageNotFound/PageNotFound";
import onboadingRoutes from "app/scenes/Onboarding/onboading.routes";
import onboardingRoutes from "app/scenes/OnboardingV2/onboarding.routes";
import masterRoutes from "app/shared/MasterLayout/master.routes";

const childRoutes = [authRoute, masterRoutes, onboadingRoutes, apptourRoutes];

const routes = [
  ...childRoutes,
  {
    path: "*",
    element: <PageNotFound />,
    strict: true,
  },

  onboardingRoutes,
];

export default routes;
