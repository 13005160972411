import { Button, Checkbox, Divider, Form, Input, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP,
  API_ENDPOINT_GET_USER_INFO,
} from "../../auth.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";

type FieldType = {
  email?: string;
  otp?: string;
};

export default function LoginOTP(props: any) {
  const [otp, setOTP] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const navigate = useNavigate();
  const location = useLocation();

  const email = location?.state?.email || "";

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: response.data.workspaces[0],
        clusterId: response.data.cluster_id,
        currentBoxId: response.data.workspaces?.[0]?.box_folder_id,
      });
      localStorage.setItem("currentWorkspace", response.data.workspaces[0]?.id);
      messageApi.success({
        type: "success",
        content: response.message,
      });
      navigate("/dashboard");

      // if (response.data.hasOnboared) {
      //   navigate("/dashboard");
      // } else {
      //   navigate("/onboarding/welcome");
      // }
    }
  };

  useEffect(() => {
    if (!location.state || !email) navigate("/auth/signin");
  }, []);

  const verifyOTP = async () => {
    setLoading(true);
    let payload = {
      email: email,
      otp: otp,
    };

    const response = await apiPost(
      API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP,
      payload
    );
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      fetchUserInfo();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resendLoginOTP = async () => {
    let payload = {
      email: email,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNIN_OTP, payload);
    if (response.status) {
      navigate("/auth/signin_otp", { state: { email: email } });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  return (
    <div className="AuthForm">
      <div className="FormHeader">
        <Typography style={{ color: colorPicker("neutral.700") }}>
          Welcome
        </Typography>
        <Typography
          style={{
            fontSize: 40,
            color: colorPicker("neutral.950"),
            marginTop: -6,
          }}
        >
          Verify OTP
        </Typography>

        <Typography
          style={{ color: colorPicker("neutral.700"), marginTop: 8 }}
          variant="caption"
        >
          We have sent you a one time password on your provided email{" "}
          <strong>{email}</strong>
        </Typography>
      </div>
      <div className="FieldContainer">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={verifyOTP}
          autoComplete="off"
        >
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="otp"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.OTP
                onChange={(otpValue) => setOTP(otpValue)}
                size="large"
              />
            </Form.Item>
          </div>

          <div className="FieldGroup" style={{ marginTop: 38 }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              size="large"
              htmlType="submit"
              // disabled={!email || !password}
              loading={isLoading}
            >
              Verify
            </Button>
          </div>

          <Typography
            style={{
              marginTop: 8,
              textDecoration: "underline",
              cursor: "pointer",
              color: "var(--base-color-blue)",
            }}
            weight={500}
            variant="caption"
            onClick={resendLoginOTP}
          >
            Resend OTP
          </Typography>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
