import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import { apiGet } from "app/services/apiServices";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import { API_ENDPOINT_ONBOARDING_BOOKING_DATA } from "../../onboarding.constants";
import Loader from "app/shared/Loader";
import { Button } from "antd";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";

const initialColDef = [
  {
    headerName: "Organization Name",
    field: "org_name",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 300,
    // rowGroup: true,
    enableRowGroup: true,
    // hide: true,
    formatType: "ENTITY",
    // rowGroup: true,
  },
  {
    headerName: "Admin Email",
    field: "admin_email",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 260,
    enableRowGroup: true,
    hide: true,
  },
  // {
  //   headerName: "Expense Client ID",
  //   field: "expense_client_id",
  //   filter: "agTextColumnFilter",
  //   sortable: true,
  //   minWidth: 200,
  // },

  {
    headerName: "Missing Invoice",
    field: "GSTInvoiceUrlExists",
    minWidth: 200,
    enableRowGroup: true,
    enableValue: true,
    enablePivot: true,
  },

  {
    headerName: "Booking ID",
    field: "bookingId",
    minWidth: 200,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: "GST Count",
    field: "GSTCount",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "GST Invoice URL Count",
    field: "GSTInvoiceUrlCount",
    // filter: "agNumberColumnFilter",
    // sortable: true,
    minWidth: 200,
    // enableRowGroup: true,
    // pivot: true,
    enableValue: true,
    enablePivot: true,
  },
  {
    headerName: "MMT GSTN",
    field: "MMT GSTN",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Hotel GSTN",
    field: "Hotel GSTN",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  // {
  //   headerName: "Customer GSTN",
  //   field: "Customer GSTN",
  //   filter: "agTextColumnFilter",
  //   sortable: true,
  //   minWidth: 200,
  // },
  {
    headerName: "Payment Source",
    field: "Payment Source",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Booking Action",
    field: "Booking Action",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Booking Mode",
    field: "Booking Mode",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Number of Nights",
    field: "No of Nights",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Number of Rooms",
    field: "No of Rooms",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Hotel Country",
    field: "Hotel country",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
    enableRowGroup: true,
  },
  {
    headerName: "Hotel City",
    field: "Hotel City",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
    enableRowGroup: true,
  },
  {
    headerName: "Tier",
    field: "Tier",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Hotel Name",
    field: "Hotel Name",
    filter: "agTextColumnFilter",
    sortable: true,
    minWidth: 200,
    enableRowGroup: true,
  },
  {
    headerName: "Hotel Star Rating",
    field: "Hotel Star Rating",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    enableRowGroup: true,
    enableValue: true,
    enablePivot: true,
  },
  {
    headerName: "Total Booking Amount",
    field: "totalBookingAmount",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
  {
    headerName: "Total Hotel Accommodation Charges",
    field: "totalHotelAccommodationCharges",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
  {
    headerName: "Total Hotel GST",
    field: "totalHotelGST",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
  {
    headerName: "Total Hotel CGST",
    field: "totalHotelCGST",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
  {
    headerName: "Total Hotel SGST",
    field: "totalHotelSGST",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
  {
    headerName: "Total Hotel IGST",
    field: "totalHotelIGST",
    filter: "agNumberColumnFilter",
    sortable: true,
    minWidth: 200,
    formatType: "AMOUNT",
    enableValue: true,
  },
];

export default function UnReconciledHotel(props) {
  const [columnDefs, setColumnDefs] = useState(initialColDef);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = useRef();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  useEffect(() => {
    setColumnDefs(initialColDef);
  }, []);

  return (
    <div
      className="UnReconContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          padding: "12px 20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button size="small" type="primary" onClick={handleExportTableData}>
          Export
        </Button>
      </div> */}
      <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader title="fetching your booking data from Makemytrip" />
          </div>
        ) : (
          <AgTableServer
            //@ts-ignore
            rowData={[]}
            columnDefs={columnDefs}
            endpoint={API_ENDPOINT_ONBOARDING_BOOKING_DATA + "hotel"}
            // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
            // enablePDFViewer
            ref={gridRef}
            showGrandTotal
            useWorkspace={props.singleWorkspace}
          />
        )}
      </div>
    </div>
  );
}
