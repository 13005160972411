import Typography from "app/shared/Typography";
import "./JoinWorkspace.scss";
import { colorPicker } from "app/utils/color.helper";
import OTP from "antd/es/input/OTP";
import { Button, Input, message } from "antd";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { useEffect, useState } from "react";
import OnboardingHeader from "../OnboardingHeader";
import { apiGet, apiPost } from "app/services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import {
  API_ENDPOOINT_ONBOARDING_INITIATE,
  API_ENDPOOINT_VERIFY_JOIN_INVITE_CODE,
} from "../../onboardingv2.constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP } from "app/scenes/Auth/auth.constants";
import { emit } from "process";
import { useRecoilState } from "recoil";
import { onboardInfo, userInfo } from "app/config/States/users";
export default function JoinWorkspace(props: any) {
  const [activeScreen, setActiveScreen] = useState(0);
  const [emailOTP, setEmailTOP] = useState("");
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const { inviteCode } = useParams();
  const [newEmail, setNewEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [userOnboardInfo, setOnboardInfo] = useRecoilState(onboardInfo);

  const navigate = useNavigate();
  useEffect(() => {
    if (!inviteCode) {
      navigate("/auth/login");
    }
    getTokenInfo();
  }, []);
  const getTokenInfo = async (emailinfo = null) => {
    setValidating(true);
    const response = await apiPost(API_ENDPOOINT_ONBOARDING_INITIATE, {
      token: inviteCode,
      ...(emailinfo ? emailinfo : {}),
    });
    if (response.status) {
      console.log("response", response);
      setTokenInfo(response.data);
      messageApi.open({
        type: "success",
        content: "We hav sent you an OTP over mail",
      });
      setOnboardInfo({
        ...response.data,
        currentWorkspace: response?.data?.workspace?.[0]?.id,
        currentWorkspaceName: response?.data?.workspace?.[0]?.name,
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not verify token looks expired",
      });
    }
    setValidating(false);
  };

  const submitEmailOTP = async () => {
    setLoading(true);
    const payload = {
      email: newEmail || tokenInfo?.email,
      otp: emailOTP,
    };
    console.log("payload", payload);
    const response = await apiPost(
      API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP,
      payload
    );
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("currentWorkspace", tokenInfo?.workspace?.[0]?.id);
      messageApi.open({
        type: "success",
        content: "Email OTP Verified",
      });
      navigate("/onboarding2/dataview", {
        state: { tokenInfo: tokenInfo },
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not verify token looks expired",
      });
    }
    setLoading(false);
  };
  const handleUpdateEmail = async (email: any) => {
    setActiveScreen(0);
    setNewEmail(email.email);
    getTokenInfo(email);
  };
  return (
    <div className="JoinWorkspace">
      <div className="HeaderContainer">
        <OnboardingHeader title={tokenInfo?.workspace?.[0]?.name || ""} />
      </div>
      {validating ? (
        <Loader />
      ) : (
        <div className="wrapperContainer">
          <div className="Container">
            {activeScreen === 0 ? (
              <div className="FormContainer">
                <Typography
                  variant="xs"
                  style={{ color: colorPicker("neutral.700") }}
                >
                  Welcome to workspace of
                </Typography>
                <Typography
                  style={{ color: colorPicker("neutral.800") }}
                  weight={600}
                  variant="h6"
                >
                  {tokenInfo?.workspace?.[0]?.name || "Test Workspace"}
                </Typography>
                <Typography
                  variant="xs"
                  style={{ marginTop: 14, color: colorPicker("neutral.700") }}
                >
                  Verify your company email{" "}
                  <b style={{ color: colorPicker("neutral.800") }}>
                    {newEmail || tokenInfo?.email}
                  </b>{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setActiveScreen(1)}
                  >
                    Change
                  </span>
                </Typography>

                <div className="OtpContainer">
                  <Input.OTP
                    size="large"
                    onChange={(otpData: any) => setEmailTOP(otpData)}
                  />
                  <Typography
                    variant="xs"
                    style={{
                      textDecoration: "underline",
                      marginTop: 14,
                      color: colorPicker("neutral.700"),
                      cursor: "pointer",
                    }}
                    onClick={() => getTokenInfo(null)}
                  >
                    Resend OTP
                  </Typography>
                </div>
                <div className="ActionContainer">
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    size="large"
                    onClick={submitEmailOTP}
                    loading={isLoading}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            ) : (
              <ChangeEmail
                updateEmail={handleUpdateEmail}
                GoBack={() => setActiveScreen(0)}
              />
            )}
          </div>
          <img
            style={{ widows: 172, height: 24 }}
            src={poweredFinkraft}
            alt="poweredLogo"
          />
        </div>
      )}
      {contextHolder}
    </div>
  );
}

function ChangeEmail(props: any) {
  const [email, setEmail] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  return (
    <div className="FormContainer">
      <Typography
        style={{
          marginBottom: 8,
          color: colorPicker("neutral.800"),
          cursor: "pointer",
        }}
        onClick={() => props.GoBack()}
        variant="xs"
      >
        <ArrowLeftOutlined style={{ marginRight: 4 }} />
        Go Back
      </Typography>
      <Typography variant="xs" style={{ color: colorPicker("neutral.700") }}>
        Welcome to workspace of
      </Typography>
      <Typography
        style={{ color: colorPicker("neutral.800") }}
        weight={600}
        variant="h6"
      >
        {userDetails?.currentWorkspaceName}
      </Typography>

      <div className="OtpContainer">
        <Input
          placeholder="Email"
          size="large"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="ActionContainer">
        <Button
          type="primary"
          style={{ width: "100%" }}
          size="large"
          onClick={() => props.updateEmail({ email: email })}
        >
          Change
        </Button>
      </div>
    </div>
  );
}
