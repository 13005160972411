import { Button, Divider, Tag, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import Typography from "app/shared/Typography";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import Uploader from "app/shared/Uploader";
import * as csv from "csvtojson";
import { apiPostForm } from "app/services/apiServices";
import { API_ENDPOINT_STORAGE_S3_UPLOAD } from "app/scenes/Invoices/invoices.constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OnboardUnReconciled from "../OnboardUnReconcilied";

export default function UploadBookingData(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [viewType, setViewType] = useState("");
  const navigate = useNavigate();

  return (
    <div
      className="Greeting"
      style={{ padding: "20px 28px", display: "flex", flexDirection: "column" }}
    >
      {viewType === "DATA" ? (
        <OnboardUnReconciled goNext={props.goNext} />
      ) : (
        <div
          className="UploadContainer"
          style={{
            padding: "40px 20px",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={() => setViewType("DATA")}
              style={{ marginRight: 24 }}
              loading={isLoading}
            >
              Fetch Your MMT Data
            </Button>
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={() => navigate("/integrations")}
              loading={isLoading}
            >
              Add Integrations
            </Button>
          </div>
        </div>
      )}

      {contextHolder}
    </div>
  );
}
