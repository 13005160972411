import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import { apiGet } from "app/services/apiServices";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";
import { API_ENDPOINT_ONBOARDING_BOOKING_DATA } from "../../onboarding.constants";
import Loader from "app/shared/Loader";
import { Button } from "antd";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";

const initialColDef = [
  {
    headerName: "Booking Data",
    children: [
      {
        headerName: "Booking ID",
        field: "Booking_ID",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "PNRNo",
        field: "PNR_No",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "Airline Name",
        field: "Airline_Name",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
        formatType: "AIRLINE",
        ...handleCellRenderer(),
      },
      {
        headerName: "Travel Date",
        field: "Travel_Date",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "From City",
        field: "From_City",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "To City",
        field: "To_City",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "Journey Type",
        field: "Journey_Type",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
        enablePivot: true,
      },
      {
        headerName: "ECONOMY",
        field: "ECONOMY",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
        enablePivot: true,
      },
      {
        headerName: "Total Fare",
        field: "Total_Fare",
        filter: "agNumberColumnFilter",
        sortable: true,
        minWidth: 200,
        formatType: "AMOUNT",
        enableValue: true,
        ...handleCellRenderer(),
      },
      {
        headerName: "Airline CGST",
        field: "Airline_CGST",
        filter: "agNumberColumnFilter",
        sortable: true,
        minWidth: 200,
        formatType: "AMOUNT",
        enableValue: true,
        ...handleCellRenderer(),
      },
    ],
  },
  {
    headerName: "Invoice Data",
    children: [
      {
        headerName: "Invoice No",
        field: "invoiceNo",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "Payment Method",
        field: "paymentMethod",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
      {
        headerName: "Invoice Date",
        field: "invoiceDate",
        filter: "agTextColumnFilter",
        sortable: true,
        minWidth: 300,
        enableRowGroup: true,
      },
    ],
  },
];

export default function UnReconciledHotel(props) {
  const [columnDefs, setColumnDefs] = useState(initialColDef);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = useRef();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  useEffect(() => {
    setColumnDefs(initialColDef);
  }, []);

  return (
    <div
      className="UnReconContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          padding: "12px 20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button size="small" type="primary" onClick={handleExportTableData}>
          Export
        </Button>
      </div> */}
      <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader title="fetching your booking data from Makemytrip" />
          </div>
        ) : (
          <AgTableServer
            //@ts-ignore
            rowData={[]}
            columnDefs={columnDefs}
            endpoint={API_ENDPOINT_ONBOARDING_BOOKING_DATA + "flight"}
            // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
            // enablePDFViewer
            ref={gridRef}
            useWorkspace={props.singleWorkspace}
          />
        )}
      </div>
    </div>
  );
}
