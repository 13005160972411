import Typography from "app/shared/Typography";
import "./OnboardingHeader.scss";
import { colorPicker } from "app/utils/color.helper";
import { Button } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
export default function OnboardingHeader(props: any) {
  const navigate = useNavigate();
  return (
    <div
      className="OnboardingHeader"
      style={{
        boxShadow: props.noShadow ? "none" : "rgba(0, 0, 0, 0.1) 0px 4px 12p",
        backgroundColor: props.noShadow ? "transparent" : "white",
      }}
    >
      <div className="OnboardingHeaderLeft">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/61/Makemytrip_logo.svg"
          alt="headerLogo"
        />
        <Typography
          style={{ marginLeft: 24, color: colorPicker("neutral.900") }}
          variant="h6"
        >
          {props.title ? "Welcome to " + props.title : ""}
        </Typography>
      </div>
      {props.showButton ? (
        <div className="OnboardingHeaderRight">
          <Button
            type="dashed"
            icon={<VideoCameraOutlined />}
            style={{ background: "transparent" }}
            onClick={() => navigate("/onboarding2/creds")}
          >
            Add GST Creds
          </Button>

          <Button
            type="dashed"
            icon={<VideoCameraOutlined />}
            style={{ background: "transparent" }}
            onClick={() => navigate("/onboarding2/invite_user")}
          >
            Invite User
          </Button>
        </div>
      ) : (
        <div className="OnboardingHeaderRight">
          <Typography
            style={{
              marginLeft: 24,
              color: colorPicker("neutral.900"),
              marginRight: 24,
            }}
          >
            Get Support
          </Typography>

          <Button
            type="dashed"
            icon={<VideoCameraOutlined />}
            style={{ background: "transparent" }}
            onClick={() => navigate("/tour")}
          >
            Demo
          </Button>
        </div>
      )}
    </div>
  );
}
